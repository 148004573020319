<nz-select
  nzShowSearch nzAllowClear nzServerSearch
  nzOptionHeightPx="50"
  nzDropdownClassName="select-warehouse"
  [nzCustomTemplate]="tplSelect"
  [nzFilterOption]="nzFilterOption"
  [nzDropdownRender]="tplBottom"
  [nzNotFoundContent]="tplNodata"
  [nzDisabled]="disabled"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange()"
  (nzScrollToBottom)="loadMore()"
  (nzOnSearch)="search($event)">
  <nz-option *ngIf="searchKeyword" nzDisabled nzCustomContent class="dummy-option-item">
    <div class="wh-row center-children search-result-notice">
      <i nz-icon nzType="search" nzTheme="outline" style="margin-right: 6px;"></i>
      <div *ngIf="!isLoading" style="margin-right: 6px; font-style: italic;">
        <span *ngIf="listData.length == 0">No result</span>
        <span *ngIf="listData.length > 0">Result</span>
        for keyword
      </div>
      <div *ngIf="isLoading" style="margin-right: 6px; font-style: italic;">Searching for keyword</div>
      <b>{{searchKeyword}}</b>
      <i nz-icon nzType="close" nzTheme="outline" class="clickable" style="margin-left: 6px;" (click)="search('')"></i>
    </div>
  </nz-option>
  <nz-option *ngFor="let item of listData" nzCustomContent [nzValue]="item.id" [nzLabel]="item.id" [nzDisabled]="isDisabled(item)">
    <div class="wh-row">
      <div class="wh-name">{{getWarehouseName(item)}}</div>
      <div class="wh-address">{{getWarehouseAddress(item)}}</div>
    </div>
  </nz-option>
</nz-select>
<ng-template #tplSelect let-selected>
  <div class="wh-row">
    <div class="wh-name">{{getWarehouseName(getWarehouseById(selected.nzValue))}}</div>
    <div class="wh-address">{{getWarehouseAddress(getWarehouseById(selected.nzValue))}}</div>
  </div>
</ng-template>
<ng-template #tplNodata>
  <div class="wh-loadmore">
    <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
    <span *ngIf="!isLoading">No data</span>
  </div>
</ng-template>
<ng-template #tplBottom>
  <div class="wh-loadmore" *ngIf="isLoading && listData.length > 0">
    <i nz-icon nzType="loading" nzTheme="outline"></i>
    <i class="text">Load more data</i>
  </div>
</ng-template>