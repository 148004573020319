import { Observable, Subscription, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';

type FnExtractResponseData<T> = (resp: any) => T

interface LoadResultCallback<T = any> {
  success?: (result: T) => void,
  error: (err: any) => void,
}

export class DataLoader<T> {
  private _isLoading: boolean | undefined = undefined;
  private _data: T | undefined;
  private sub: Subscription;

  get isLoading(): boolean { return this._isLoading ?? false }
  get data() { return this._data }
  get alreadyLoaded(): boolean { return this._isLoading === false }

  constructor() {}

  /** load with data repo */
  load(dataRepoFetching: Observable<any>, cb?: LoadResultCallback<T>): void {
    this.sub?.unsubscribe();
    this._isLoading = true;
    this.sub = dataRepoFetching.pipe(map(resp => {
      this._isLoading = false;
      this._data = resp;
      return this._data;
    })).pipe(catchError(err => {
      this._isLoading = false;
      return throwError(err);
    })).subscribe(result => cb?.success?.(result), err => cb?.error?.(err));
  }

  /** load with ApiService */
  loadWithApiService(apiServiceRequest: Observable<any>, cb?: LoadResultCallback<T>): void {
    this.sub?.unsubscribe();
    this._isLoading = true;
    this.sub = apiServiceRequest.pipe(map(resp => {
      this._isLoading = false;
      this._data = this._extractResponseData(resp);
      return this._data;
    })).pipe(catchError(err => {
      this._isLoading = false;
      return throwError(err);
    })).subscribe(result => cb?.success?.(result), err => cb?.error?.(err));
  }

  private _extractResponseData: FnExtractResponseData<T> = resp => <T>resp.data;      // nếu API trả về body có cấu trúc khác thì cần override hàm này

  extractResponseData(fn: (resp: any) => T) {
    this._extractResponseData = fn;
    return this;
  }

  dispose() {
    this.sub?.unsubscribe();
  }
}
