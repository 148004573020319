import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { VehicleType } from "@wearewarp/types/data-model"
import { environment } from "@env/environment";
import { map } from 'rxjs/operators';

type FnSuccess = (data: VehicleType[]) => void;
type FnError = (err: any) => void

@Injectable({providedIn: 'root'})
export class VehicleTypeService {

    get baseUrl() { return `${environment.backendUrl}/v2/vehicle-types` }
    constructor(private api: ApiService) {
    }

    public listAll(success: FnSuccess, error: FnError) {
        // TODO Caching
        return this.api.GET(this.baseUrl)
        .pipe(
            map((response: VehicleType[]) => {
                const storageVehicle: VehicleType = {
                    order: 999, 
                    code: 'STORAGE',
                    name: 'Storage',
                    selectable: true,
                    quotable: false,
                    active: true,
                    parent: 'AUTO',
                    palletCapacity: 0,
                };
                return [...response, storageVehicle];
            })
        )
        .subscribe(
            resp => {
                if (resp)
                    success?.(resp)
                else
                    error?.({'message': 'Not found'})
            }, err => {
                error?.(err)
            }
        )
    }
}