import { Const } from '@const/Const';
import { TaskType } from '@wearewarp/types';
import { AddressUS, DelayCode } from '@wearewarp/types/data-model';
import { MasterConst, MasterDataChangeDateTimeReason, ServiceOptionType } from '@wearewarp/types/rest-api';
import { MasterAdmin } from '@wearewarp/types-server-admin';
import { of } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { ApiService } from './api.service';
import { Crypto } from './crypto';
import { DateUtil } from './date-utils';
import { Log } from './log';

interface NameCode {
  name: string,
  code: string,
}

export class MasterData {
  private static data: MasterAdmin = <any>{};
  private static mapCountries = {};  // map by _id
  private static countryUS: any = {};
  private static mapServiceOptions: {[key: string]: ServiceOptionType} = {};  // map by _id
  private static supportedCountries: any = {};
  private static canadaProvinces: any = {};
  private static mexicoStates: any = {};

  
  public static fetch(api: ApiService) {
    if (Object.keys(this.data).length > 0) {
      return of(this.data); // already had data
    }
    let url = Const.API_PUBLIC('masters');
    return api.GET(url).pipe(
      map(resp => {
        Log.d('master data: ', resp);
        this.set(resp.data)
        return resp.data;
      })
    );
  }

  public static getFromResponse(data: any) {
    let masterData: MasterAdmin;
    if (typeof data == 'string') {
      masterData = JSON.parse(Crypto.decryptText(data));
    } else {
      masterData = data;
    }
    return masterData;
  }

  public static set(data: MasterAdmin) {
    this.data = Object.assign(this.data || {}, data);
    if (data.countries) {
      for (let i = 0; i < data.countries.length; i++) {
        this.mapCountries[data.countries[i]._id] = data.countries[i];
        if (data.countries[i].alpha2Code == 'US') {
          this.countryUS = data.countries[i];
        }
      }
    }
    if (data.service_options) {
      for (let key of ['pickup', 'delivery', 'addition']) {
        for (let item of data.service_options[key]) {
          this.mapServiceOptions[item._id] = item;
        }
      }
    }
    if (data.timezones) {
      DateUtil.listTimezones = data.timezones;
    }
    if (data?.countries_states?.countries){
      this.supportedCountries = data?.countries_states?.countries
    }
    if (data?.countries_states?.canada_provinces){
      this.canadaProvinces = data?.countries_states?.canada_provinces
    }
    if (data?.countries_states?.mexico_states){
      this.mexicoStates = data?.countries_states?.mexico_states
    }
  }

  public static getAddressText(addr: AddressUS): string {
    if (!addr) return '';
    let countryName;
    if (addr.countryId && addr.countryId != Const.CountryId_USA) {
      countryName = `(${MasterData.getCountryNameById(addr.countryId)})`;
    }
    let addrArray = [addr.street, addr.street2, addr.city, addr.state, addr.zipcode, countryName];
    return addrArray.filter(it => it).join(', ')
  }

  public static getTimeAllowUncheckInvoiceSent(): number {
    const minutes = this.data.timeAllowUncheckInvoiceSent ?? 24 * 60;
    const milliSeconds = minutes * 60 * 1000;
    return milliSeconds;
  }

  // return array of URLs
  public static getTemplateOrder(): Array<string> {
    return this.data?.template?.order;
  }

  // return array of URLs
  public static getTemplateOrderItems(): Array<string> {
    return this.data?.template?.orderItems;
  }

  // return array of URLs
  public static getTemplateCrossDockItems(): Array<string> {
    return this.data?.template?.orderItems;
  }

  // return array of URLs
  public static getTemplateZipRate(): Array<string> {
    return this.data?.template?.zipRate;
  }

  // return a URL
  public static getTemplateCarrierZipcodeList(): string {
    return this.data?.template?.carrier?.list_zipcode;
  }

  public static getTemplateManifest(): Array<string> {
    return this.data?.template?.manifest;
  }

  public static getAllCountries(): Array<any> {
    return this.data?.countries ?? [];
  }

  /**
   * @deprecated use getAllShipmentModesV2 instead
   */
  public static getAllShipmentModes(): Array<any> {
    return this.data?.shipment_modes ?? [];
  }

  /**
   * Danh sách tất cả shipment mode bao gồm cả version 1 và 2 (do server trả về)
   * Các bản ghi order cũ dùng version 1 => deprecated
   * Hiện tại thì dùng version 2
   */
  private static _getAllShipmentModes(): Array<any> {
    return this.data?.shipment_modes ?? [];
  }
  
  // Lấy danh sách shipment mode theo version
  public static getShipmentModesByVersion(version: number): Array<any> {
    return this._getAllShipmentModes().filter(it => it.version == version);
  }

  public static getAllShipmentModesV2(): Array<any> {
    return this.getShipmentModesByVersion(Const.shipmentModeVersion.version_2);
  }

  // Lấy danh sách shipment mode theo version (có cùng version với id truyền vào)
  public static getShipmentModesSameVersion(shipmentModeId: String): Array<any> {
    const shipmentMod = this._getAllShipmentModes().find(it => it._id == shipmentModeId);
    if (shipmentMod) {
      return this.getShipmentModesByVersion(shipmentMod.version);
    }
    return this.getAllShipmentModesV2();
  }

  public static getAllEquipments(): Array<any> {
    return this.data?.equipments ?? [];
  }

  public static getShipmentModeName(id: string): string {
    return this._getAllShipmentModes().filter(it => it._id == id)[0]?.name;
  }
  public static getShipmentModeNameById(id: string): string {
    return this._getAllShipmentModes().filter(it => it.id == id)[0]?.name;
  }

  public static getEquipmenName(id: string): string {
    return this.getAllEquipments().filter(it => it._id == id)[0]?.name;
  }
  public static getEquipmenNameById(id: string): string {
    return this.getAllEquipments().filter(it => it.id == id)[0]?.name;
  }

  public static getAllVehicleTypes(): Array<any> {
    return this.data.vehicleTypes ?? []
  }
  
  public static getCountries(ids: Array<string>) {
    let arr = [];
    for (let id of ids) {
      arr.push(this.mapCountries[id]);
    }
    return arr;
  }

  public static getCountryById(id) {
    return this.mapCountries[id];
  }

  public static getCountryNameById(id) {
    if (!this.mapCountries[id]) return id;
    return this.mapCountries[id].name;
  }
  
  public static getCountryByAlpha2Code(code) {
    for (let key of Object.keys(this.mapCountries)) {
      if (this.mapCountries[key]?.alpha2Code == code) return this.mapCountries[key];
    }
    return null;
  }
  
  public static getStatesUS(): Array<NameCode> {
    return this.countryUS.states || [];
  }

  public static getStateUSByCode(code: string) {
    for (let item of this.countryUS.states) {
      if (item.code == code) {
        return item;
      }
    }
    return null;
  }

  public static getContact() {
    return this.data?.contact;
  }
  public static getDelayCodes(): Array<DelayCode> {
    return this.data.delay_codes;
  }
  public static getDelayCodesByType(type: TaskType): Array<DelayCode> {
    const delayCodeType = {
      PICKUP: 'PICKUP',
      DROPOFF: 'DELIVERY'
    }[type];
    const list = this.data.delay_codes.filter(it => it.type == delayCodeType);
    return list.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
  }

  public static get mapboxToken() {
    return this.data.mapBoxAccessToken;
  }

  public static get ShipmentServiceOptionsPickup(): Array<ServiceOptionType> {
    return this.data.service_options.pickup;
  }

  public static get ShipmentServiceOptionsDelivery(): Array<ServiceOptionType> {
    return this.data.service_options.delivery;
  }

  public static get ShipmentServiceOptionsAddition(): Array<ServiceOptionType> {
    return this.data.service_options.addition;
  }

  public static getServiceOptionsByType(type: TaskType) {
    switch (type) {
      case Const.TaskType.PICKUP: return this.ShipmentServiceOptionsPickup;
      case Const.TaskType.DROPOFF: return this.ShipmentServiceOptionsDelivery;
      default: return [];
    }
  }

  public static getServiceOptionById(id: string): ServiceOptionType {
    return this.mapServiceOptions[id];
  }

  public static getServiceOptionName(objOrId: string | {_id?: string, id?: string}): string {
    if (!objOrId) return '';
    const serviceOptionId: string = typeof objOrId == 'string' ? objOrId : (objOrId.id || objOrId._id);
    return this.getServiceOptionById(serviceOptionId)?.name ?? serviceOptionId;
  }

  public static isServiceOptionTypeNegative(id: string): boolean {
    return this.getServiceOptionById(id)?.negative ?? false;
  }
  public static getCostNegativeMarginReasons(): Array<any> {
    return this.data.cost_negative_margin_reasons;
  }

  public static getChangeDateTimeReasons(): MasterDataChangeDateTimeReason[] {
    return this.data.changeDateTimeReasons ?? [];
  }

  public static getSupportedCountries(): Array<any> {
    return this.supportedCountries;
  }

  public static getCanadaProvinces(): Array<NameCode> {
    return this.canadaProvinces;
  }

  public static getMexicoStates(): Array<NameCode> {
    return this.mexicoStates;
  }

  public static get pwPolicyText(): string { return this.data.pwPolicyText }

  public static get const(): MasterConst {
    const defaultConst: MasterConst = {intervalDriverOnline: 60000};
    return this.data.const ?? defaultConst;
  }

  public static getCountriesStates() {
    return {
      us: MasterData.getStatesUS(),
      ca: MasterData.getCanadaProvinces(),
      mx: MasterData.getMexicoStates()
    };
  }

  // Để dùng với nz-select + nz-option-group
  public static getCountriesStates_forSelectGroup() {
    return [
      {name: 'USA', code: 'us', states: MasterData.getStatesUS()},
      {name: 'Canada', code: 'ca', states: MasterData.getCanadaProvinces()},
      {name: 'Mexico', code: 'mx', states: MasterData.getMexicoStates()},
    ];
  }

  public static getDoNotInvoiceReasons() {
    return this.data?.do_not_invoice_reasons ?? [];
  }

}