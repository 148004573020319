import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ResponseAdminClientForFilter } from "@wearewarp/types-server-admin/client";
import { PaginationData } from '@app/model/PaginationData';
import { UIHelper } from '@services/UIHelper';
import { Subscription } from 'rxjs';
import { ApiService } from "@services/api.service";
import { Utils } from "@services/utils";
import { ApiUri } from "@app/data-repo/const";

type ListItem = ResponseAdminClientForFilter;

@Component({
  selector: '[select-client]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SelectClient
    }
  ]
})
export class SelectClient implements OnInit, OnDestroy, ControlValueAccessor {
  private paginationData = new PaginationData<ListItem>();
  get listData(): ListItem[] { return this.paginationData ? this.paginationData.list_data : [] }
  isLoading = false;
  disabled = false;
  value;
  @Input() multiple: boolean = false;
  @Output() modelChange = new EventEmitter<string>();
  searchKeyword = '';
  private skip = 0;
  private readonly limit = 100;
  private canLoadMore = false;

  constructor(private api: ApiService) {
  }

  get nzMode() { return this.multiple ? 'multiple' : 'default' }

  nzFilterOption = (): boolean => true;

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onChange = value => {};
  onTouched = () => {};

  writeValue(v: string): void {
    this.value = v
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private sub: Subscription;
  private async fetchData(ops?: {loadMore?: boolean}) {
    this.isLoading = true;
    this.sub?.unsubscribe();
    const input = {skip: this.skip, limit: this.limit, keyword: this.searchKeyword, excludeSubAccount: true}
    const url = Utils.appendQueryStringIntoUrl(ApiUri.Client.searchForSelection, input);
    this.sub = this.api.get<PaginationData<ListItem>>(url).subscribe(
      data => {
        this.canLoadMore = data.list_data.length == this.limit;
        if (!ops?.loadMore) {
          this.paginationData.reset();
        }
        this.paginationData.append(data);
        this.isLoading = false;
      }, err => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private justSelected = false;
  onModelChange() {
    if (this.value == null && this.searchKeyword.length > 0) {
      // Trường hợp user ấn icon clear trên ô search
      this.search('');
    } else {
      // Khi user chọn 1 item thì ô search bị clear => hàm nzOnSearch được gọi với keyword = "" => listData bị thay đổi.
      // Trường hợp này cần tránh gọi lại hàm search để giữ cho listData không đổi mỗi khi user chọn 1 item.
      this.justSelected = true;
      setTimeout(() => this.justSelected = false, 200);
    }
    this.onChange(this.value);
    this.modelChange.emit(this.value)
  }

  search(keyword: string) {
    const _keyword = keyword.trim();
    if (_keyword == this.searchKeyword) return;
    if (this.justSelected && _keyword == '') return;       // avoid unexpected search due to search input clear when user select an item
    this.searchKeyword = _keyword;
    this.skip = 0;
    this.fetchData();
  }

  loadMore() {
    if (!this.canLoadMore || this.isLoading) {
      return;
    };
    this.skip = this.listData.length;
    this.fetchData({loadMore: true});
  }

  getItemName(item: ListItem): string {
    return (item.name ?? '').trim() || 'NO NAME';
  }

}