import { ExtendValidators } from "@app/admin/base/validator";
import { Component } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { KeyStorage } from "@const/KeyStorage";

interface FormDataSetupServer {
  urlServerMain: string,
  urlServerAuth: string,
  urlApiPlanning: string,
  urlDataorch: string,
}

@Component({
  selector: '[setup-server]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss',]
})
export class SetupServer extends BaseFormItem<FormDataSetupServer> {

  protected formGroupDeclaration: FormGroupDeclaration = {
    urlServerMain: {label: 'Server main', required: true, placeHolder: 'http://', validators: ExtendValidators.validateUrl},
    urlServerAuth: {label: 'Server auth', required: true, placeHolder: 'http://', validators: ExtendValidators.validateUrl},
    urlApiPlanning: {label: 'api-planing', initialValue: 'http://localhost:9093', validators: ExtendValidators.validateUrl },
    urlDataorch: {label: 'dataorch', initialValue: 'http://localhost:9097', validators: ExtendValidators.validateUrl },
  };

  get isCreateNew(): boolean {
    return !this.model || !this.model.urlServerMain;
  }

  constructor() {
    super();
    this.model = {
      urlServerMain: this.getUrlServerMain(),
      urlServerAuth: this.getUrlServerAuth(),
      urlApiPlanning: this.getUrlApiPlanning(),
      urlDataorch: this.getUrlDataorch(),
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private getUrlServerMain(): string {
    return localStorage.getItem(KeyStorage.urlServerMain) || '';
  }

  private getUrlServerAuth(): string {
    return localStorage.getItem(KeyStorage.urlServerAuth) || '';
  }

  private getUrlApiPlanning(): string {
    return localStorage.getItem(KeyStorage.urlApiPlanning) || '';
  }

  private getUrlDataorch(): string {
    return localStorage.getItem(KeyStorage.urlDataorch) || '';
  }

  private setUrlServerMain(url: string) {
    localStorage.setItem(KeyStorage.urlServerMain, url);
  }

  private setUrlServerAuth(url: string) {
    localStorage.setItem(KeyStorage.urlServerAuth, url);
  }

  private setUrlApiPlanning(url: string) {
    localStorage.setItem(KeyStorage.urlApiPlanning, url);
  }

  private setUrlDataorch(url: string) {
    localStorage.setItem(KeyStorage.urlDataorch, url);
  }

  onBtnSubmit() {
    const formData = <FormDataSetupServer>this.getFormData(true);
    this.setUrlServerMain(formData.urlServerMain);
    this.setUrlServerAuth(formData.urlServerAuth);
    this.setUrlApiPlanning(formData.urlApiPlanning);
    this.setUrlDataorch(formData.urlDataorch);
    window.location.href = '';
  }

  onBtnCancel(): void {
    window.location.href = '';
  }

}