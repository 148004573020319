import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SelectWarehouse } from '.';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzIconModule,
    NzSelectModule,
  ],
  declarations: [
    SelectWarehouse,
  ],
  exports: [
    SelectWarehouse
  ],
  providers: [
  ]
})
export class ModuleSelectWarehouse { }