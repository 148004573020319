import { ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";

// Dùng cho href của thẻ a
export function getLinkDashboardForEnitty(entity: string, id: string): string {
  switch (entity) {
    case 'users':
    case 'drivers':
    case 'carriers':
    case 'developers':
      return `/dashboard/${entity}/${id}`;
    case 'clients':
      return `/dashboard/customers/${id}`;
    default:
      return '';
  }
}

export function getLinkDashboard(user: ResponseWhenBy_User): string {
  return getLinkDashboardForEnitty(user?.entity, user?.id);
}