import { UsTimezone } from '@wearewarp/types';
import { TimeWindow } from '@wearewarp/types/data-model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
import { Const } from "@const/Const";

type DateInput = string | number | Date;

interface DisplayTimeWindowOptions {
  timezone?: string,
  format?: string,                  // cả ngày và giờ
  formatDateOnly?: string,          // để xem from & to có cùng ngày không
}

export interface TimeZoneObject {
  fullName: string,              // America/New_York
  shortName: string,              // Eastern
  abbreviation: string,       // EST
}

export class DateUtil {
  public static Timezone_LA = 'America/Los_Angeles';

  public static get listUsTimezones(): Array<UsTimezone> {return [
    'Atlantic', 'Eastern', 'Central', 'Mountain', 'Pacific', 'Alaska', 'Hawaii', 'Samoa'
  ]}

  private static _listTimezones: Array<TimeZone> = [];
  private static _mapTimezoneByCode: {[key: string]: TimeZone} = {};

  public static set listTimezones(value: Array<TimeZone>) {
    DateUtil._listTimezones = value;
    for (let item of value) {
      this._mapTimezoneByCode[item.tzCode] = item;
    }
  }

  public static get listTimezones(): Array<TimeZone> {
    return DateUtil._listTimezones;
  }

  public static getTimezone(tzCode: string): TimeZone {
    return this._mapTimezoneByCode[tzCode];
  }

  // https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
  public static mapTimezoneUS(tzShort: UsTimezone, defaultValue = 'America/Los_Angeles'): string {
    switch (tzShort) {
      case 'Atlantic': return 'America/Halifax';      // GMT-04:00 Canada/Atlantic
      case 'Eastern': return 'America/New_York';      // GMT-05:00
      case 'Central': return 'America/Chicago';       // GMT-06:00
      case 'Mountain': return 'America/Denver';       // GMT-07:00
      case 'Pacific': return 'America/Los_Angeles';   // GMT-08:00
      case 'Alaska': return 'America/Anchorage';      // GMT-09:00
      case 'Hawaii': return 'Pacific/Honolulu';       // GMT-10:00
      case 'Samoa': return 'Pacific/Pago_Pago';       // GMT-11:00
      default: return defaultValue;          // default value
    }
  }

  public static usTimezoneShortDesc(tz: UsTimezone) {
    switch (tz) {
      case 'Atlantic': return `AST`;
      case 'Eastern': return `EST`;
      case 'Central': return `CST`;
      case 'Mountain': return `MST`;
      case 'Pacific': return `PST`;
      case 'Alaska': return `AKST`;
      case 'Hawaii': return `HAST`;
      case 'Samoa': return `SMST`;
      default: return tz;
    }
  }

  public static timezoneStandardToUsShort(tz: string) {
    switch (tz) {
      case 'America/Halifax': return 'AST';
      case 'America/New_York': return 'EST';
      case 'America/Chicago': return 'CST';
      case 'America/Denver': return 'MST';
      case 'America/Los_Angeles': return 'PST';
      case 'America/Anchorage': return 'AKST';
      case 'Pacific/Honolulu': return 'HAST';
      case 'Pacific/Pago_Pago': return 'SMST'
    }
  }

  public static timezoneObjectFromShortName(shortName: UsTimezone): TimeZoneObject {
    switch (shortName) {
      case 'Atlantic': return {abbreviation: `AST`, shortName, fullName: 'America/Halifax'};
      case 'Eastern': return {abbreviation: `EST`, shortName, fullName: 'America/New_York'};
      case 'Central': return {abbreviation: `CST`, shortName, fullName: 'America/Chicago'};
      case 'Mountain': return {abbreviation: `MST`, shortName, fullName: 'America/Denver'};
      case 'Pacific': return {abbreviation: `PST`, shortName, fullName: 'America/Los_Angeles'};
      case 'Alaska': return {abbreviation: `AKST`, shortName, fullName: 'America/Anchorage'};
      case 'Hawaii': return {abbreviation: `HAST`, shortName, fullName: 'Pacific/Honolulu'};
      case 'Samoa': return {abbreviation: `SMST`, shortName, fullName: 'Pacific/Pago_Pago'};
      default: return {abbreviation: shortName, shortName, fullName: shortName};
    }
  }

  public static timezoneObjectFromFullName(fullName: string): TimeZoneObject {
    switch (fullName) {
      case 'America/Halifax': return {abbreviation: `AST`, shortName: 'Atlantic', fullName};
      case 'America/New_York': return {abbreviation: `EST`, shortName: 'Eastern', fullName};
      case 'America/Chicago': return {abbreviation: `CST`, shortName: 'Central', fullName};
      case 'America/Denver': return {abbreviation: `MST`, shortName: 'Mountain', fullName};
      case 'America/Los_Angeles': return {abbreviation: `PST`, shortName: 'Pacific', fullName};
      case 'America/Anchorage': return {abbreviation: `AKST`, shortName: 'Alaska', fullName};
      case 'Pacific/Honolulu': return {abbreviation: `HAST`, shortName: 'Hawaii', fullName};
      case 'Pacific/Pago_Pago': return {abbreviation: `SMST`, shortName: 'Samoa', fullName};
      default: return {abbreviation: fullName, shortName: fullName, fullName: fullName};
    }
  }

  public static dateToString(d: Date|string|number, pattern = Const.DATETIME_FORMAT_FROM_DB) {
    return dayjs(d).format(pattern);
  }

  public static stringToDate(s: string, pattern = Const.DATETIME_FORMAT_FROM_DB): Date {
    return dayjs(s, pattern).toDate();
  }

  // https://day.js.org/docs/en/display/format
  public static format(s: string|number|Date, pattern: string): string {
    if (!s) return '';
    let d = dayjs(s);
    return d.format(pattern);
  }

  /**
   * @param s iso date string
   * @returns {Date}
   */
  public static isoDate(s: string): Date {
    return dayjs(s).toDate();
  }

  public static isValid(d: Date): boolean {
    if (!d) return false;
    return d instanceof Date && !isNaN(d.getTime());
  }

  public static clone(d: Date): Date {
    return new Date(d.getTime());
  }

  /**
   * Check if d1 and d2 are different
   * d1, d2 are the date ISO string from database, with format '2022-11-23T14:35:14.000Z'
   */
  public static diff(date1: DateInput, date2: DateInput, options: {skipTime?: boolean, skipSecond?: boolean} = {}): boolean {
    if (!date1 && !date2) return true;
    if ((date1 && !date2) || (!date1 && date2)) return true;
    let d1 = new Date(date1).toISOString();
    let d2 = new Date(date2).toISOString();
    if (!d1 && !d2) return false;
    if ((d1 && !d2) || (!d1 && d2)) return true;
    if (options?.skipTime) {
      // chỉ so sánh ngày, bỏ qua giờ
      let _d1 = d1.substring(0, 10);
      let _d2 = d2.substring(0, 10);
      return _d1 != _d2;
    } else {
      if (options?.skipSecond) {
        // so sánh ngày giờ nhưng chỉ lấy đến phút, bỏ qua giây
        let _d1 = d1.substring(0, 16);
        let _d2 = d2.substring(0, 16);
        return _d1 != _d2;
      }
      // so sánh đầy đủ ngày giờ
      return d1 != d2;
    }
  }

  public static diffDate(d1: DateInput, d2: DateInput): boolean {
    return DateUtil.diff(d1, d2, {skipTime: true});
  }

  /**
   * So sánh xem date1 cách date2 mấy ngày. Nếu kết quả nhỏ hơn 0 thì tức là date1 trước date2
   */
  public static diffDays(date1: DateInput, date2: DateInput): number {
    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    return d1.diff(d2, 'days');
  }

  /**
   * Chỉ quan tâm đến ngày, bỏ qua giờ (xác định hôm qua, hôm nay, ngày mai...)
   * Nếu kết quả nhỏ hơn 0 thì tức là date1 trước date2
   */
  public static diffDaysLocal(date1: DateInput, date2: DateInput, timezone: string) {
    const date1Local = DateUtil.displayLocalTime(date1, {timezone, format: 'YYYY-MM-DD'});
    const date2Local = DateUtil.displayLocalTime(date2, {timezone, format: 'YYYY-MM-DD'});
    const arr1 = date1Local.split('-');
    const y1 = Number(arr1[0]);
    const m1 = Number(arr1[1]);
    const d1 = Number(arr1[2]);
    const arr2 = date2Local.split('-');
    const y2 = Number(arr2[0]);
    const m2 = Number(arr2[1]);
    const d2 = Number(arr2[2]);
    const dayjs1 = dayjs();
    const dayjs2 = dayjs();
    dayjs1.set('year', y1).set('month', m1 - 1).set('date', d1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dayjs2.set('year', y2).set('month', m2 - 1).set('date', d2).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    return dayjs1.diff(dayjs2, 'days');
  }

  // Chỉ lấy hours, minutes dưới dạng string HH:mm
  public static getHHmm(d: Date): string {
    if (typeof d === 'string') {
      return d;
    }
    if (d == null) {
      return null;
    }
    return DateUtil.dateToString(d, 'HH:mm');
  }

  // Creare a Date object and assign value of hours, minutes
  public static fromHHmm(s: string): Date {
    if (!s) return null;
    let d = new Date();
    let arr = s.split(':');
    let h = Number(arr[0]);
    let m = Number(arr[1]);
    d.setHours(h);
    d.setMinutes(m);
    return d;
  }

  // compare time from Date() to String("HH:mm")
  // return true if different
  public static diffHHmm(d1: Date|string, d2: Date|string): boolean {
    let isString = (v) => typeof v === 'string' || v instanceof String;
    if (isString(d1) && isString(d2) && (<string>d1).length == 5 && (<string>d1).length == (<string>d2).length) {
      // Nếu cả 2 đều là string dạng HH:mm rồi thì so sánh string luôn
      return d1 != d2;
    }
    let date1: Date, date2: Date;
    if (isString(d1)) {
      date1 = DateUtil.isoDate(<string>d1);
    } else {
      date1 = <Date>d1;
    }
    if (isString(d2)) {
      date2 = DateUtil.isoDate(<string>d2);
    } else {
      date2 = <Date>d2;
    }
    let str1 = DateUtil.dateToString(date1, 'HH:mm');
    let str2 = DateUtil.dateToString(date2, 'HH:mm');
    return str1 != str2;
  }

  // compare time from Date() to String("YYYY-MM-DD")
  // return true if different
  public static diffYYYYMMDD(d1: Date|string, d2: Date|string): boolean {
    let isString = (v) => typeof v === 'string' || v instanceof String;
    let date1: Date, date2: Date;
    if (isString(d1)) {
      date1 = DateUtil.isoDate(<string>d1);
    } else {
      date1 = <Date>d1;
    }
    if (isString(d2)) {
      date2 = DateUtil.isoDate(<string>d2);
    } else {
      date2 = <Date>d2;
    }
    let str1 = DateUtil.dateToString(date1, 'YYYY-MM-DD');
    let str2 = DateUtil.dateToString(date2, 'YYYY-MM-DD');
    return str1 != str2
  }

  // Convert 'YYYY-MM-DD' string to Date object
  public static YYYYMMDDtoDate(dateStr: string): Date {
    if (!/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(dateStr)) {
      throw Error('dateStr must be YYYY-MM-DD');
    }
    let arr = dateStr.split('-');
    let year = Number(arr[0]);
    let month = Number(arr[1]);
    let date = Number(arr[2]);
    let dateObj = new Date();
    dateObj.setFullYear(year);
    dateObj.setMonth(month-1);
    dateObj.setDate(date);
    return dateObj;
  }

  // isoStr date time ISO string from database
  public static displayLocalTime(isoStr, options: {timezone?: string, format?: string}) {
    if (!isoStr) return '';
    let timezone = options.timezone || 'America/Los_Angeles';
    let format = options.format || 'MMM D, YYYY, h:mm a';
    return dayjs.tz(new Date(isoStr), timezone).format(format);
  }

  /**
   * Hiển thị window dạng 7/15/22 5:00 AM - 3:00 PM
   */
   public static displayTimeWindow(window: TimeWindow, options: DisplayTimeWindowOptions = {}): string {
    if (!window) return '';
    let defaultOptions: DisplayTimeWindowOptions = {format: 'MM/DD/YYYY, h:mm a', formatDateOnly: 'MM/DD/YYYY'};
    let ops: DisplayTimeWindowOptions = Object.assign(defaultOptions, options);
    if (!ops.format.startsWith(ops.formatDateOnly)) {
      throw Error('Please provide valid format and formatDateOnly');
    }
    let from = this.displayLocalTime(window.from, ops);
    let to = this.displayLocalTime(window.to, ops);
    if (from == to) {
      // Nếu trùng cả ngày giờ thì hiển thị 1 thằng thôi
      return from;
    }
    let dateFrom = this.displayLocalTime(window.from, {format: ops.formatDateOnly, timezone: ops.timezone});
    let dateTo = this.displayLocalTime(window.to, {format: ops.formatDateOnly, timezone: ops.timezone});
    let isSameDay = dateFrom == dateTo;
    if (isSameDay) {
      // Nếu cùng ngày khác giờ thì hiển thị dạng: ngày, giờ 1 - giờ 2
      return `${from} - ${to.substring(dateTo.length).replace(/^[^0-9]*/g, '')}`;
    } else {
      // Nếu khác ngày thì hiển thị dạng: ngày 1, giờ 1 - ngày 2, giờ 2
      return `${from} - ${to}`;
    }
  }

  // Hàm này chỉ chạy trên front end, vì Date ăn theo timezone của front end, nên muốn lấy timezone khác thì phải convert
  // VD chạy web ở VN nhưng giờ chọn cho timezone Mĩ thì dùng hàm này để convert
  // Lấy dữ liệu từ datepicker -> cho chạy qua hàm này -> rồi mới đẩy lên server
  public static convertLocalTime(d: Date, targetTimezone: string): Date {
    if (!d) return d;
    let timezone = this.getTimezone(targetTimezone);
    if (!timezone) throw Error(`Invalid timezone ${targetTimezone}`);
    return dayjs.tz(`${dayjs(d).format('YYYY-MM-DD HH:mm:ss')}`, targetTimezone).toDate();
  }

  // Làm ngược lại với hàm convertLocalTime ở trên
  // Khi lấy dữ liệu từ server, hiển thị lên UI đúng với ngày giờ mà user đã nhập trước đó
  // VD chạy web ở VN nhưng giờ chọn cho timezone Mĩ thì cần hiển thị ra giờ Mĩ
  // Lấy dữ liệu từ server -> cho chạy qua hàm này -> rồi mới bỏ vào datepicker
  public static convertLocalTime2(isoDate: string, originTimezone: string) {
    if (isoDate == null) return null;
    let formated = dayjs.tz(new Date(isoDate), originTimezone).format('YYYY-MM-DD HH:mm:ss');
    return dayjs(formated).toDate();
  }

  // Get value from date/time picker, handle timezone, then return object {from: ISOString, to: ISOString}
  public static toTimeWindow(date: Date | string, fromTime: Date | string, toTime: Date | string, timezone: string): TimeWindow {
    if (!date || !fromTime || !toTime) {
      return null;
    }
    //fix nếu datatype == string
    date = new Date(date)
    fromTime = new Date(fromTime);
    toTime = new Date(toTime);
    const fromTimeHHmm  = DateUtil.getHHmm(fromTime);
    const toTimeHHmm  = DateUtil.getHHmm(toTime);
    //Fixbug hàm setMonth
    fromTime.setDate(1)
    fromTime.setFullYear(date.getFullYear());
    fromTime.setMonth(date.getMonth());
    fromTime.setDate(date.getDate());
    //Fixbug hàm setMonth
    if (fromTimeHHmm <= toTimeHHmm) {
      toTime.setDate(1)
      toTime.setFullYear(date.getFullYear());
      toTime.setMonth(date.getMonth());
      toTime.setDate(date.getDate());
    } else {
      let nextDay = new Date(date);
      nextDay.setDate(date.getDate()+1);
      toTime.setDate(1)
      toTime.setFullYear(nextDay.getFullYear());
      toTime.setMonth(nextDay.getMonth());
      toTime.setDate(nextDay.getDate());
    }

    if (timezone) {
      fromTime = DateUtil.convertLocalTime(fromTime, timezone);
      toTime = DateUtil.convertLocalTime(toTime, timezone);
    }
    return {from: fromTime.toISOString(), to: toTime.toISOString()}
  }

  public static fromTimeWindow(input: {from?: string, to?: string}, timezone: string): {date?: string, fromTime?: string, toTime?: string} {
    if ((<any>input).date && (<any>input).fromTime && (<any>input).toTime) {
      return <any>input;
    }
    let obj = {};
    for (let childKey of ['from', 'to']) {
      let dt = input[childKey];
      if (dt) {
        dt = DateUtil.convertLocalTime2(dt, timezone);
        obj[childKey == 'from' ? 'fromTime' : 'toTime'] = dt.toISOString();
        if (!obj['date']) {
          obj['date'] = DateUtil.clone(dt).toISOString();
        }
      }
    }
    return obj;
  }

  public static displayDuration(milliseconds: number, options: {hour?: string, minute?: string, second?: string, skipSecond?: boolean, noSpaceBeforeUnit?: boolean} = {}): string {
    let unitHour = options.hour ?? 'hour';
    let unitMin = options.minute ?? 'min';
    let unitSec = options.second ?? 'sec';
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let msg = '';
    let spaceBeforeUnit = options.noSpaceBeforeUnit ? '' : ' ';
    if (hours > 0) {
      msg += hours + `${spaceBeforeUnit}${unitHour}`;
    }
    if (minutes > 0) {
      if (msg.length > 0) msg += ' ';
      msg += minutes + `${spaceBeforeUnit}${unitMin}`;
    }
    if (options.skipSecond) {
      if (!msg) {
        msg += "< 1" + `${spaceBeforeUnit}${unitMin}`;
      }
      return msg;
    }
    if (seconds > 0) {
      if (msg.length > 0) msg += ' ';
      msg += seconds + `${spaceBeforeUnit}${unitSec}`;
    } else {
      if (msg.length == 0) msg = `0${spaceBeforeUnit}${unitSec}`;
    }
    return msg;
  }

  public static toBeginOfDay(value: Date): Date {
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    return value;
  }

  public static toEndOfDay(value: Date): Date {
    value.setHours(23);
    value.setMinutes(59);
    value.setSeconds(59);
    value.setMilliseconds(999);
    return value;
  }

  public static getDateFrom(value: Date) {
    if (value instanceof Date) {
      // set time to the beginning of the day
      value.setHours(0);
      value.setMinutes(0);
      value.setSeconds(0);
      value.setMilliseconds(0);
      return value.toISOString();
    } else {
      return value;
    }
  }

  public static getDateTo(value: Date) {
    if (value instanceof Date) {
      // set time to the end of the day
      value.setHours(23);
      value.setMinutes(59);
      value.setSeconds(59);
      value.setMilliseconds(999);
      return value.toISOString();
    } else {
      return value;
    }
  }

  public static get1MonthAgo() {
    return dayjs().add(-1, "month").toDate();
  }

  public static toBeginOfThisWeek() {
    return dayjs().startOf('week').toDate();
  }

  public static toEndOfThisWeek(): Date{
    return dayjs().endOf('week').toDate();
  }
  public static toBeginOfNextWeek(): Date{
    return dayjs().startOf('week').add(7, 'day').toDate();
  }
  public static toEndOfNextWeek(): Date{
    return dayjs().endOf('week').add(7, 'day').toDate();
  }
  public static toBeginOfThisMonth(): Date{
    return dayjs().startOf('month').toDate();
  }
  public static toEndOfThisMonth(): Date{
    return dayjs().endOf('month').toDate();
  }

  public static getYesterDay() {
    return dayjs().add(-1, "day").toDate();
  }
  public static isToday(date) {
    return dayjs(date).isToday();
  }
  public static isYesterday(date) {
    return dayjs(date).isYesterday();
  }

  public static get30DaysAgo() {
    return dayjs().add(-30, "day").toDate();
  }

  public static fromWindow(input: {from?: string, to?: string}, timezone: string): {date?: string, from?: string, to?: string} {
    if ((<any>input).date && (<any>input).from && (<any>input).to) {
      return <any>input;
    }
    let obj = {};
    for (let childKey of ['from', 'to']) {
      let dt = input[childKey];
      if (dt) {
        dt = DateUtil.convertLocalTime2(dt, timezone);
        obj[childKey] = dt.toISOString();
        if (!obj['date']) {
          obj['date'] = DateUtil.clone(dt).toISOString();
        }
      }
    }
    return obj;
  }
  public static getLocalTimeZone() {
    return dayjs.tz.guess();
  }
  public static formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(date).format(format);
  }

  public static getUTCTimestamp() {
    return dayjs.utc().valueOf();
  }

  public static isPassDeadline(deadline: number): boolean {
    return Date.now() - deadline > 0;
  }

  public static getTimeDurationFromWindow(window: TimeWindow) {
    let timeFrom = DateUtil.isoDate(window.from).getTime();
    let timeTo = DateUtil.isoDate(window.to).getTime();
    return timeTo - timeFrom;
  }

  public static timestampToDate(timestamp: number): Date {
    return dayjs(timestamp).toDate();
  }

  public static getStrictTime(date: Date, time: Date, timezone: string): Date {
    date = new Date(date)
    time = new Date(time);
    //Fixbug hàm setMonth
    time.setDate(1)
    time.setFullYear(date.getFullYear());
    time.setMonth(date.getMonth());
    time.setDate(date.getDate());
    if (timezone) {
      time = DateUtil.convertLocalTime(time, timezone);
    }
    return time;
  }

  public static getDateOfNextWeek(dayOfWeek: number): Date {
    return dayjs().add(1, 'week').startOf('week').add(dayOfWeek, 'day').toDate();
  }

  public static timeAgo(date: string|number|Date) {
    return dayjs(date).fromNow();
  }
}
