import { Const } from "@const/Const";
import { Utils } from "./utils";
import { DateUtil } from "./date-utils";
import { RoleManager } from "./role-manager";
import { ModelRoutingCost } from "@app/admin/routing-tool/interface";
import { AddressUS, Contact, DeliveryInfo, LatLng, RoutingVehicle, ShipmentItem, TimeWindow, VehicleType } from "@wearewarp/types/data-model";
import { TaskType } from "@wearewarp/types";
import { FormDataShipmentCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { FuelCostType, VolumeDiscountType } from "@app/enum";
import { MasterData } from "./master.data";
import { InputHelper } from "./input-helper";
import { getApp } from ".";
import { ResponseAdminFinJobDetailTx } from "@wearewarp/types-server-admin/fin";
import { FinTxRefCode, FinTxRefDetailType } from "@wearewarp/js-const-finance";
import { WarpId } from "@wearewarp/universal-libs";
import { HyperLinkObject } from "@app/interfaces";
import { ReponseAddress } from "@wearewarp/types/rest-api/common";

interface OrderForCreatingHyperLink {
  id: string,
  warpId: number,
  code?: string,
  client: {name: string},
  shipmentType: string,
  metadata: {
    shipments: {
      code?: string,
      warpId: number,
    }[]
  }
}

interface GetDeliveryInfoTimeOptions {
  showWindow?: boolean,         // hiển thị window (from - to) thay vì hiển thị mỗi from
  format?: string,              // format đầy đủ ngày giờ
  formatDateOnly?: string,      // format ngày không có giờ, để check xem window có cùng ngày không, dùng trong trường hợp cần hiển thị window
  appointmentFlag?: string,     // để hiển thị đây là ngày giờ được hẹn sau này chứ không phải ngày giờ nhập từ lúc tạo shipment, VD để hiển thị như này "Jan 28, 2023 5:29 am (Appointment Scheduled)" thì gán appointmentFlag = "(Appointment Scheduled)"
}

export class BizUtil {

  static createHyperLinkForJob(job: any, url: string = null): HyperLinkObject {
    let firstShipment = job.shipments?.[0];
    let hyperLinkUrl = url?.split('?')?.[0];
    const clientName = job.clients?.[0]?.name || 'Customer N/A';
    let hyperLinkText = `${clientName} / ${this.getWarpId(firstShipment)}`;
    if (job.shipments && job.shipments.length > 1) {
      hyperLinkText += ` (+${job.shipments.length-1})`;
    }
    hyperLinkText += ` / Route ${job.code}`;
    if (!hyperLinkUrl) {
      hyperLinkUrl = `${Const.routeAdminDispatchList}/${job.warpId || job.id}`;
    }
    return {hyperLinkText, hyperLinkUrl};
  }

  static createHyperLinkForOrder(order: OrderForCreatingHyperLink): HyperLinkObject {
    const firstShipment = (order.metadata?.shipments ?? [])[0];
    const shipmentCount = (order.metadata?.shipments ?? []).length;
    const clientName = order.client?.name ?? '';
    const orderId = order.code || WarpId.showOrder(order);
    const shipmentType = order.shipmentType ?? '';
    let hyperLinkText = clientName;
    if (firstShipment) {
      const shipmentId = firstShipment.code || WarpId.showShipment(firstShipment);
      hyperLinkText += ` / ${shipmentId}`;
      if (shipmentCount > 1) {
        hyperLinkText += ` (+${shipmentCount-1})`;
      }
    }
    hyperLinkText += ` / Order ${orderId}`;
    if (shipmentType) {
      hyperLinkText += ` / ${shipmentType}`;
    }
    const hyperLinkUrl = `${location.origin}${Const.routeAdminOrderList}/${order.id}`;
    return {hyperLinkText, hyperLinkUrl};
  }

  static getOrderItemDesc(orderItem, options: {defaultQtyUnit?: string} = {}): string {
    let str = '';
    if (orderItem.qty) {
      if (orderItem.qtyUnit) {
        str = Utils.displayCount(orderItem.qty, orderItem.qtyUnit || options.defaultQtyUnit);
      } else {
        str = `${orderItem.qty}`;
      }
      if (orderItem.weightPerUnit) {
        let weight = orderItem.qty * orderItem.weightPerUnit;
        str += `, ${weight} ${orderItem.weightUnit}`;
      }
    }
    return str;
  }

  static getShipmentContainerName(item): string {
    if(!item.type) return "N/A";
    if(item.containerLicense) return Utils.capitalizeFirstLetter(item.type) + " " + item.containerLicense;
    if(item.containerId) return Utils.capitalizeFirstLetter(item.type) + " #" + item.containerId;
    if(item.barcode) return Utils.capitalizeFirstLetter(item.type) + " " + item.barcode;
    if(item._id) return Utils.capitalizeFirstLetter(item.type) + " #" + item._id;
    return "N/A";
  }

  static canUpdateTaskStatus(dropoffTask, listTasks): boolean {
    let authUser = getApp().getAuthUser();
    if (RoleManager.isAdminReadOnlyRole(authUser)) {
      return false;
    }

    if (dropoffTask.type == Const.TaskType.DROPOFF) {
      for (let task of listTasks) {
        if (task.type == Const.TaskType.PICKUP) {
          if ((task?.order_id && task.order_id == dropoffTask?.order_id  && task.deliveryId == dropoffTask?.deliveryId) || (task?.shipmentContainerId && task.shipmentContainerId == dropoffTask.shipmentContainerId)) {
            // Nếu 1 task PICKUP failed thì task DROPOFF tương ứng sẽ bị coi là failed và ko được update nữa.
            return (
              task.status != Const.TaskStatus.failed &&
              task.status != Const.TaskStatus.canceled
            );
          }
        }
      }
    }
    return true;
  }

  static getDeliveryInfoLocation(info: DeliveryInfo): LatLng {
    return {latitude: info?.addr?.metadata?.latitude, longitude: info?.addr?.metadata?.longitude}
  }

  static getDeliveryInfo(type: TaskType, order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    let info;
    if (deliveryId) {
      info = (order?.deliveryInfos ?? []).filter(it => it.id == deliveryId && it.type == type)[0];
    } else {
      info = (order?.deliveryInfos ?? []).filter(it => it.type == type)[0];
    }
    return info;
  }

  static getPickInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.TaskType.PICKUP, order, deliveryId);
  }

  static getDropInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.TaskType.DROPOFF, order, deliveryId);
  }

  // Dùng để hiển thị window time cho deliveryInfo
  // Nếu
  static getDeliveryInfoTime(deliveryInfo: DeliveryInfo, options: GetDeliveryInfoTimeOptions = {}): string {
    let format = options.format ?? Const.FORMAT_GUI_DATETIME
    let timezone = deliveryInfo?.addr?.metadata?.timeZoneStandard;
    let {window, isAppointment} = BizUtil.getTimeWindowForDeliveryInfo(deliveryInfo);
    let str = '';
    if (options.showWindow) {
      str = DateUtil.displayTimeWindow(window, {timezone, format, formatDateOnly: options.formatDateOnly})
    } else {
      let time = window?.from;
      str = DateUtil.displayLocalTime(time, {timezone, format});
    }
    if (str && isAppointment && options.appointmentFlag) {
      str += `${options.appointmentFlag}`;
    }
    return str;
  }

  // Cờ isAppointment cho biết window có phải lấy từ info.appointmentInfo hay không
  static getTimeWindowForDeliveryInfo(info: DeliveryInfo): {window?: TimeWindow, isAppointment: boolean, tz?: string} {
    let appointment = info?.appointmentInfo;
    let window = info?.windows?.[0];
    if (info?.requiresAppointment && appointment?.from) {
      // Nếu có requiresAppointment = true và có thông tin appointment thì phải lấy appointment
      return {window: appointment, isAppointment: true}
    }
    return {window, isAppointment: false, tz: info?.addr?.metadata?.timeZoneStandard};
  }

  static getFromToTimeWindow(task) {
    let {window, isAppointment} = BizUtil.getTimeWindowForDeliveryInfo(task.info);
    if (!Utils.isObjectNotEmpty(window)) {
      return "N/A";
    }
    // if (this.isETA) {
    //   window = task.etaTime;
    // }
    let timezone = task.address?.metadata?.timeZoneStandard;
    let from = window.from;
    let to = window.to;
    from = DateUtil.displayLocalTime(from, {
      timezone,
      format: "M/D/YY h:mm A",
    });
    to = DateUtil.displayLocalTime(to, { timezone, format: "M/D/YY h:mm A" });
    return {from, to, timezone, isAppointment};
  }

  static getArrivedDepartedByTask(task) {
    const status = task.status;
    const timezone = task?.info.addr?.metadata?.timeZoneStandard;

    const types = {
      arrived: {
        arrived: task?.statusChangeLog?.arrived?.changeWhen
      },
      departed: {
        failed: task?.statusChangeLog?.failed?.changeWhen,
        succeeded: task?.statusChangeLog?.succeeded?.changeWhen
      }
    }
    let rs: any = {};
    for(let key of ['arrived', 'departed']) {
      let time = key === 'arrived' ? types[key]['arrived'] : types[key][status];
      if(time && key === 'arrived' && (![Const.TaskStatus.arrived, Const.TaskStatus.succeeded, Const.TaskStatus.failed].includes(status))) time = null;
      if(!time && key === 'arrived') time = types['departed'][status];
      rs[key] = time ? DateUtil.convertLocalTime2(time, timezone) : 'N/A';
    }
    if (task?.delay) {
      rs.delayCodeId = task?.delay?.delayCodeId;
      rs.note = task?.delay?.note;
    }
    return rs;
  }

  static isVehiclesTheSame(v1: RoutingVehicle, v2: RoutingVehicle): boolean {
    let isTheSameVehicleType = v1?.vehicleType?.code == v2?.vehicleType?.code;
    return isTheSameVehicleType;
  }

  // returns number of seconds
  static getTimeFromCost(cost: ModelRoutingCost) {
    return (cost?.travel_time ?? 0) + (cost?.service_time ?? 0) + (cost?.idle_time ?? 0);
  }

  static getDistanceFromCost(cost: ModelRoutingCost, options: {unit: 'mi'|'km'} = {unit: 'km'}) {
    let km = (cost?.travel_distance ?? 0) / 1000;
    if (options.unit == 'mi') {
      return Const.metersToMiles(km*1000);
    }
    return km;
  }

  static getLocationName(info: DeliveryInfo): string {
    return info?.locationName ?? '';
  }

  static getPrimaryContact(info: DeliveryInfo): any {
    return info?.contacts?.filter(it => it.type === 'primary')[0]
  }

  static isContactNotEmpty(contact: Contact): boolean {
    if (!contact) {
      return false;
    }
    return (contact.email ?? '').length > 0 ||
            (contact.firstName ?? '').length > 0 || (contact.lastName ?? '').length > 0 || (contact.fullName ?? '').length > 0 ||
            (contact.phone ?? '').length > 0;
  }

  static getItemSumary(data: ShipmentItem) {
    let itemVolume = null;
    let shortDesc = '';
    let itemWeight = data.qty * data.weightPerUnit;
    // if (data.weightUnit == 'kgs') {
    //   // weight should be in lbs
    //   itemWeight = Const.kgToLbs(itemWeight);
    // }
    if (data.qtyUnit == 'TL') {
      shortDesc = `${data.qty} ${data.qtyUnit}, ${itemWeight} ${data.weightUnit}`;
    } else {
      if (data.sizeUnit == 'IN') {
        // volume should be in feet -> convert inches to feet
        itemVolume = Const.inchesToFeet(data.length) * Const.inchesToFeet(data.width) * Const.inchesToFeet(data.height);
      } else {
        itemVolume = data.length * data.width * data.height;
      }
      itemVolume *= data.qty;
      shortDesc = `${data.qty} ${data.qtyUnit}, ${itemWeight} ${data.weightUnit ?? 0}, ${data.length ?? 0}L x ${data.width ?? 0}W x ${data.height ?? 0}H ${data.sizeUnit?.toLowerCase()}`;
    }
    return { volume: itemVolume, weight: itemWeight, desc: shortDesc }
  }

  public static getWarpId(data) {
    return data?.warpId || data?.id || data?._id || 'Shipment N/A';
  }

  public static getClientName(data) {
    return data.metadata?.client?.name || data.client?.name;
  }

  // Format: 2 Pallets, 38x48x50 in, 650lbs, $600
  public static getItemDescWithoutName(item: ShipmentItem): string {
    let desc = `${item.qty} ${item.qtyUnit}, ${this.getItemSizeDesc(item)}, ${item.weightPerUnit ?? 0} ${item.weightUnit ?? Const.WeightUnits[0]}`;
    if (item.itemValue) {
      desc += `, ${InputHelper.formatMoney1(`${item.itemValue}`)}`;
    }
    return desc;
  }

  public static getItemSizeDesc(item: ShipmentItem): string {
    const length = item.length ?? 0;
    const width = item.width ?? 0;
    const height = item.height ?? 0;
    const unit = item.sizeUnit ?? Const.SizeUnits[0];
    return `${length}x${width}x${height} ${unit.toLocaleLowerCase()}`;
  }

  public static getItemServiceDesc(item: ShipmentItem): string {
    let str = '';
    if (item.isStackable) {
      str = 'Stackable';
    }
    if (item.isTemperatureControlled) {
      let strTemp = '';
      if (item.temperatureControlled.tempMin) {
        strTemp = `${item.temperatureControlled.tempMin}°F`;
      }
      if (item.temperatureControlled.tempMax) {
        if (strTemp.length > 0) {
          strTemp += ' 〜 ';
        }
        strTemp += `${item.temperatureControlled.tempMax}°F`;
      }
      if (strTemp.length > 0) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `Temp: ${strTemp}`;
      }
    }
    if (item.isHazardous) {
      let strHaz = 'Hazardous';
      let desc = item.hazardous.hazmatDesc || item.hazardous.hazmatClass;
      if (desc) {
        strHaz += `: ${desc}`;
      }
      if (str.length > 0) {
        str += ', ';
      }
      str += strHaz;
    }
    if(item?.shipRecInfo?.deviceId){
      str += ', Tracking Device ID: ' + item?.shipRecInfo?.deviceId
    }
    return str;
  }

  public static convertCostToUSD(cost: FormDataShipmentCost) {
    if (!cost.currency?.type || cost.currency?.type == 'USD' || !cost.currency?.fxRate) return cost;
    if (cost.transitCost.rate) {
      cost.transitCost.rate =  cost.transitCost.rate * cost.currency.fxRate;
      cost.transitCost.total =  cost.transitCost.rate * cost.transitCost.qty;
    }
    if (cost.volumeDiscount?.type == VolumeDiscountType.flatRate) {
      cost.volumeDiscount.flatRate = cost.volumeDiscount.flatRate * cost.currency.fxRate;
      cost.volumeDiscount.total = cost.volumeDiscount.flatRate;
    } else if (cost.volumeDiscount?.type == VolumeDiscountType.percentage) {
      cost.volumeDiscount.total = cost.transitCost.total * cost.volumeDiscount.percentage / 100;
    }
    if (cost.subTotal) {
      cost.subTotal = cost.transitCost.total - cost.volumeDiscount.total;
    }
    if (cost.fuelCost?.type == FuelCostType.ratePerMile) {
      cost.fuelCost.rpm = cost.fuelCost.rpm * cost.currency.fxRate;
      cost.fuelCost.total = cost.fuelCost.rpm * cost.fuelCost.qty;
    } else if (cost.fuelCost?.type == FuelCostType.percentage) {
      cost.fuelCost.total = cost.subTotal * cost.fuelCost.percentage / 100;
    }
    let grandTotal = cost.subTotal + cost.fuelCost.total;
    if (Utils.isArrayNotEmpty(cost.serviceOptions)) {
      for (let item of cost.serviceOptions) {
        item.rate = item.rate * cost.currency.fxRate;
        item.total = item.rate * item.qty;
        if (!MasterData.isServiceOptionTypeNegative(item._id)) {
          grandTotal += item.total;
        } else {
          grandTotal -= item.total;
        }
      }
    }
    cost.grandTotal = grandTotal;
    return cost;
  }

  public static convertCostFromUSDToOrther(cost: FormDataShipmentCost) {
    if (!cost.currency?.type || cost.currency?.type == 'USD' || !Utils.isNumber(cost.currency?.fxRate)) return cost;
    if (Utils.isNumber(cost.transitCost?.rate)) {
      cost.transitCost.rate =  cost.transitCost.rate / cost.currency.fxRate;
      cost.transitCost.total =  cost.transitCost.rate / cost.transitCost.qty;
    }
    if (cost.volumeDiscount?.type == VolumeDiscountType.flatRate) {
      if (Utils.isNumber(cost.volumeDiscount?.flatRate)) {
        cost.volumeDiscount.flatRate = cost.volumeDiscount.flatRate / cost.currency.fxRate;
        cost.volumeDiscount.total = cost.volumeDiscount.flatRate;
      }
    } else if (cost.volumeDiscount?.type == VolumeDiscountType.percentage) {
      if (Utils.isNumber(cost.volumeDiscount?.percentage)) {
        cost.volumeDiscount.total = cost.transitCost.total * cost.volumeDiscount.percentage / 100;
      }
    }
    if (cost.subTotal) {
      cost.subTotal = cost.transitCost.total - cost.volumeDiscount.total;
    }
    if (cost.fuelCost?.type == FuelCostType.ratePerMile) {
      if (Utils.isNumber(cost.fuelCost?.rpm)) {
        cost.fuelCost.rpm = cost.fuelCost.rpm / cost.currency.fxRate;
        cost.fuelCost.total = cost.fuelCost.rpm * cost.fuelCost.qty;
      }
    } else if (cost.fuelCost?.type == FuelCostType.percentage) {
      if (Utils.isNumber(cost.fuelCost?.percentage)) {
        cost.fuelCost.total = cost.subTotal * cost.fuelCost.percentage / 100;
      }
    }
    let grandTotal = cost.subTotal + cost?.fuelCost?.total || 0;
    if (Utils.isArrayNotEmpty(cost.serviceOptions)) {
      for (let item of cost.serviceOptions) {
        if (Utils.isNumber(item.rate) && Utils.isNumber(item.qty)) {
          item.rate = item.rate / cost.currency.fxRate;
          item.total = item.rate * item.qty;
          if (!MasterData.isServiceOptionTypeNegative(item._id)) {
            grandTotal += item.total;
          } else {
            grandTotal -= item.total;
          }
        }
      }
    }
    cost.grandTotal = grandTotal;
    return cost;
  }

  static getShipmentTypeName(code: string): string {
    switch (code) {
      case Const.ShipmentTypes.fullTruckLoad: return 'Full Truckload';
      case Const.ShipmentTypes.lessThanTruckload: return 'Less Than Truckload';
      case Const.ShipmentTypes.parcel: return 'Parcel';
      default: return code;
    }
  }

  static getVehicleName(vehicleType: VehicleType) {
    let name = '';
    if (vehicleType?.name) {
      name = vehicleType.name;
      const options = vehicleType?.options;
      if (options?.length) {
        name += ` /w ${options.map(Utils.capitalize).join(", ")}`
      }
    }
    return name;
  }

  static getStatusJob(status: string): string {
    switch (status) {
      case Const.JobStatus.created: return 'Created';
      case Const.JobStatus.inProgress: return 'In Progress';
      case Const.JobStatus.canceled: return 'Canceled';
      case Const.JobStatus.completed: return 'Completed';
      default: return 'Created';
    }
  }

  static getFullName(user: {firstName?: string, lastName?: string, fullName?: string}): string {
    if (user.fullName) return user.fullName;
    return [user.firstName ?? '', user.lastName ?? ''].join(' ').trim();
  }

  static getTxDesc(tx: ResponseAdminFinJobDetailTx): string {
    const dic1 = {
      [FinTxRefCode.route.transitCost]: 'Transit Cost',
      [FinTxRefCode.route.fuelCost]: 'Fuel Cost',
      [FinTxRefCode.route.serviceOption]: 'Service Option',
      [FinTxRefCode.order.transitCost]: 'Transit Cost',
      [FinTxRefCode.order.volumeDiscount]: 'Volume Discount',
      [FinTxRefCode.order.fuelCost]: 'Fuel Cost',
      [FinTxRefCode.order.serviceOption]: 'Service Option',
    };
    const dic2 = {
      [FinTxRefDetailType.create]: 'Added',
      [FinTxRefDetailType.change]: 'Changed',
    };
    return `${dic1[tx.ref?.code] ?? ''} ${dic2[tx.ref?.detail?.type] ?? ''}`.trim();
  }

  static buildResponseAddress(input: AddressUS|undefined): ReponseAddress {
    return {
      street: input?.street ?? '',
      street2: input?.street2 ?? '',
      city: input?.city ?? '',
      state: input?.state ?? '',
      zipcode: input?.zipcode ?? '',
      location: {
        latitude: input?.location?.latitude ?? input?.metadata?.latitude ?? 0,
        longitude: input?.location?.longitude ?? input?.metadata?.longitude ?? 0,
      },
      timezone: input?.metadata?.timeZoneStandard ?? '',
      metroCity: input?.metadata?.metroCity,
      country: input?.country,
    }
  }
}
