import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';

const TAG = '[SearchBox]';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.html',
  styleUrls: ['./search-box.scss']
})
export class SearchBoxComponent {
  @Input() searchKeyword: string = '';
  @Input() inputStyle: string = '';
  @Input() placeHolder: string = '';
  @Input() size: 'large' | 'small' | 'default' = 'default';
  @ViewChild('inputField', {static: false}) inputField: ElementRef;
  @Output() doSearch: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  private emitSearch() {
    this.doSearch.emit(this.searchKeyword);
  }

  onSearchEnter() {
    this.inputField.nativeElement.blur();
    this.searchKeyword = this.searchKeyword.trim();
    this.emitSearch();
  }

  onInputFieldFocusOut() {
    // no need
  }

  clearSearchKeyword() {
    this.searchKeyword = '';
    this.inputField.nativeElement.focus();
    this.emitSearch();
  }

  setKeyword(text) {
    this.searchKeyword = text;
  }

}
